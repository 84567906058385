import Layout from "../../components/layout"
import Heading from "../../components/heading"
import HorizontalImage from "../../components/horizontal-image"
import Text from "../../components/text"
import VerticalImage from "../../components/vertical-image"
import VerticalText from "../../components/vertical-text"
import React from 'react';
import { graphql } from "gatsby"

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "kavarna/karto-1.jpg" }) { ...fluidImage }
    photo2: file(relativePath: { eq: "kavarna/karto-2.jpg" }) { ...fluidImage }
    photo3: file(relativePath: { eq: "kavarna/karto-3.jpg" }) { ...fluidImage }
    photo4: file(relativePath: { eq: "kavarna/karto-4.jpg" }) { ...fluidImage }
    photo5: file(relativePath: { eq: "kavarna/karto-5.jpg" }) { ...fluidImage }
  }
`

const KavarnaPage = ({ data }) => (
  <Layout title="Kavárna">
    <HorizontalImage marginTop borderBottom image={data.photo1.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo2.childImageSharp.fluid} />
    <VerticalText>
      <Heading>Kavárna KARTO, Praha 7, 2017</Heading>
      <Text>
        Návrh mini kavárny, která má doplnit provozy Galerie Kartografie Praha. Prostor kavárny vznikl ze dvou menších sklepních místností sloužících jako archiv. Původní okenní otvory byly v průběhu let zazděny a anglický dvorek byl zasypán. Návrh počítá s obnovou těchto otvorů, doplněním replik původních kastlových oken a revitalizaci anglického dvorku. Mou snahou bylo zachovat původní atmosféru sklepa i v nových prostorách kavárny. Železobetonový trámový strop je zachován bez úprav, stejně jako přiznané elektrické vedení na něm. Kvůli zlepšení akustiky byla zadní stěna obložena perforovanými deskami cetris. Tyto panely jsou odkazem na modulové plechové panely na nářadí v dílně každého kutila. V první variantě návrhu byla tato perforace desek využitá ke kotvení přiznaných elektrických rozvodů po stěnách. V druhé variantě se přiznané vedení kabelů zachovalo pouze na stropě a na stěnách jej nahradil systém kladek a navijáků ovládající zavěšené industriální svítidla. Díky tomu lze svítidla zvednout ke stropu a využít prostor k promítání. Nášlapná vrstva podlahy je navržena jako bílá stěrka probarvena bronzovými šupinami. Repasované dveřní křídla jsou stejně jako okenní otvory ponechány v zelenomodré barvě. Většina nábytku (bar, lavice, židle) jsou navrženy ze světlé překližky.
      </Text>
    </VerticalText>
    <HorizontalImage marginTop borderBottom borderTop image={data.photo3.childImageSharp.fluid} />
    <HorizontalImage image={data.photo4.childImageSharp.fluid} />
    <HorizontalImage borderTop image={data.photo5.childImageSharp.fluid} />
  </Layout>
)

export default KavarnaPage
